<template>
  <b-row class="flex">
    <b-col md="5">
      <b-card>
        <h3>{{ $t("tabs.assignpermissions") }} {{ `(${roleData.name})` }}</h3>
        <div class="d-flex justify-content-between align-items-center my-2">
          <h4>{{ $t("tabs.modules") }}</h4>

          <b-button
            v-if="modeDesarrollo"
            variant="primary"
            size="sm"
            v-b-tooltip.hover.top="$t('tooltips.addmodule')"
            @click="editAddModule()"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
          <add-edit-module ref="modalAddModule" @updateModule="getModules()" />
        </div>
        <!-- BOverlay -->
        <b-overlay :show.sync="loadingModules" rounded>
          <b-list-group>
            <b-list-group-item
              v-for="item in modules"
              :key="item.id"
              :active="moduleSelect == item"
              @click="moduleSelect = item"
              class="
                d-flex
                justify-content-between
                align-items-center
                cursor-pointer
              "
            >
              <h6>{{ item.name }}</h6>
              <div v-if="modeDesarrollo">
                <b-button
                  variant="flat-dark"
                  size="sm"
                  @click="editAddModule(item)"
                  class="mr-1"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  variant="flat-danger"
                  size="sm"
                  @click="deleteModule(item)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-overlay>
      </b-card>
    </b-col>

    <b-col md="7">
      <b-card v-if="moduleSelect.permissions">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <h3>{{ $t("labels.permissions") }} - {{ moduleSelect.name }}</h3>

          <b-button
            v-if="modeDesarrollo"
            variant="primary"
            size="sm"
            v-b-tooltip.hover.top="$t('tooltips.addpermissions')"
            @click="addPermissionModule()"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
          <add-edit-permission-module
            ref="AddEditPermissionModule"
            @updatePermissionModule="getModules()"
          />
        </div>
        <b-list-group>
          <b-list-group-item
            v-for="item in moduleSelect.permissions"
            :key="item.id"
            class="
              d-flex
              justify-content-between
              align-items-center
              cursor-pointer
            "
          >
            <h6>{{ item.name }}</h6>
            <h6 v-if="modeDesarrollo">{{ item.shortName }}</h6>

            <div class="d-flex align-items-center">
              <b-button
                v-if="modeDesarrollo"
                variant="flat-danger"
                size="sm"
                @click="deletePermissionModule(item._id)"
                class="mr-1"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
              <b-form-checkbox
                v-model="item.incluir"
                class="ml-auto cursor-pointer"
              ></b-form-checkbox>
            </div>
          </b-list-group-item>
        </b-list-group>
        <!-- button update -->
        <div class="d-flex justify-content-end mt-2">
          <!-- add save -->
          <b-button
            @click="UpdatePermissionsRole(moduleSelect)"
            variant="primary"
          >
            <!-- icon check -->
            <feather-icon icon="SaveIcon" />
            <span class="text-nowrap"> {{ $t("buttons.update") }} </span>
          </b-button>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BDropdown,
  BDropdownItem,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  VBTooltip,
  BListGroup,
  BListGroupItem,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AddEditPermissionModule from "./addEditPermissionModule.vue";
import AddEditModule from "./addEditModule.vue";

export default {
  components: {
    BTable,
    BDropdown,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormGroup,
    VBTooltip,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BOverlay,
    AddEditPermissionModule,
    AddEditModule,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  data: () => ({
    fields: [
      { label: "Name", key: "name" },
      { label: "Description", key: "description" },
      // actions
      {
        label: "Actions",
        key: "actions",
        sortable: false,
        width: "150px",
      },
    ],
    modules: [],
    moduleSelect: {},
    modeEdit: false,
    roleData: {},
    loadingModules: false,
  }),
  computed: {
    modeDesarrollo() {
      console.log(process.env.VUE_APP_MODE);
      // NODE_EMP

      return process.env.VUE_APP_MODE == "development";
    },
  },
  methods: {
    editAddModule(item) {
      if (item) {
        this.$refs.modalAddModule.editModule(item);
      } else {
        this.$refs.modalAddModule.addModule();
      }
    },
    async getModules() {
      try {
        this.loadingModules = true;
        const { data } = await this.$store.dispatch("moduleRoles/getModules");
        this.modules = data.modules;
        this.modules.forEach((module) => {
          module.permissions.forEach((permission) => {
            this.roleData.modules.forEach((roleModule) => {
              if (module.name == roleModule.name) {
                roleModule.permissions.forEach((rolePermission) => {
                  if (permission.name == rolePermission.name) {
                    permission.incluir = rolePermission.incluir;
                  }
                });
              }
            });
          });
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loadingModules = false;
      }
    },
    async deleteModule({ _id }) {
      const response = await this.$bvModal.msgBoxConfirm(
       this.$t('rols.comfirmDelete'),
        {
          title: this.$t('rols.pleaseConfirm'),
          size: "sm",
          okVariant: "danger",
          okTitle: this.$t('buttons.yes'),
          cancelTitle: this.$t('buttons.no'),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );
      if (response) {
        try {
          await this.$store.dispatch("moduleRoles/deleteModule", _id);
          await this.showRole();
          await this.getModules();
        } catch (error) {
          console.log("error", error);
        }
      }
    },
    async showRole() {
      try {
        const { data } = await this.$store.dispatch(
          "moduleRoles/showRole",
          this.$route.params.id
        );
        this.roleData = data;
      } catch (error) {
        console.log("error", error);
      }
    },
    async UpdatePermissionsRole(moduleSelect) {
      try {
        let roleUpdate = JSON.parse(JSON.stringify(this.roleData));
        const findIndexModule = roleUpdate.modules.findIndex(
          (module) => module.name == moduleSelect.name
        );
        if(findIndexModule !== -1){
          roleUpdate.modules.forEach((module) => {
            if (module.name === moduleSelect.name) {
              module.permissions = moduleSelect.permissions.filter(
                (permission) => {
                  if (permission.incluir) {
                    return true;
                  }
                }
              );
            }
          });
        }else{
          roleUpdate.modules.push(moduleSelect);
        }
        
        
        // roleUpdate.modules = modulesUpdate;
        await this.$store.dispatch("moduleRoles/updateRole", roleUpdate);
        this.showRole();
        this.getModules();
      } catch (error) {
        console.log("error", error);
      }
    },
    addPermissionModule() {
      this.$refs.AddEditPermissionModule.addPermission(this.moduleSelect);
    },
    async deletePermissionModule(_id) {
      try {
        const response = await this.$bvModal.msgBoxConfirm(
          this.$t('rols.comfirmDelete'),
          {
            title: this.$t('rols.pleaseConfirm'),
            size: "sm",
            okVariant: "danger",
            okTitle: this.$t('buttons.yes'),
            cancelTitle: this.$t('buttons.no'),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        );

        if (response) {
          const permissionIndex = this.moduleSelect.permissions.findIndex(
            (item) => {
              return item.name == name;
            }
          );
          this.moduleSelect.permissions.splice(permissionIndex, 1);
          await this.$store.dispatch(
            "moduleRoles/updateModule",
            this.moduleSelect
          );
          this.getModules();
          this.showRole();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.showModalAddModule = false;
      }
    },
  },
  async created() {
    await this.showRole();
    await this.getModules();
  },
};
</script>

<style>
</style>