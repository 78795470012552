<template>
  <b-modal
        v-model="showModalAddModule"
        size="sm"
        modal-class="modal-primary"
        cancel-variant="outline-secondary"
        centered
        :title="`${ modeEdit ? $t('tabs.edit'):$t('tabs.add') } ${$t('rols.module')}`"
        hide-footer
    >
        <b-form-group>
        <label for="Name">{{$t('labels.name')}}</label>
        <b-form-input
            id="Name"
            type="text"
            v-model="moduleData.name"
            :placeholder="$t('labels.name')"
        />
        </b-form-group>
        <b-form-group>
        <label for="Description">{{$t('labels.description')}}</label>
        <b-form-input
            id="Description"
            type="text"
            v-model="moduleData.description"
            :placeholder="$t('labels.description')"
        />
        </b-form-group>
        <div class="d-flex justify-content-end  mt-2">
            <b-button
                variant="outline-secondary"
                @click="showModalAddModule = false"
                class="mr-1"
            >
                {{$t('buttons.cancel')}}
            </b-button>
            <b-button
                variant="primary"
                @click="addUpdateModule()"
            >         
                {{$t('buttons.accept')}} 
            </b-button>
        </div>
        
    </b-modal>
</template>

<script>
import {
  BButton, 
  BModal, 
  VBModal, 
  BForm, 
  BFormInput, 
  BFormGroup,
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
export default {
    components: {
        BButton,
        BModal,
        BForm,
        BFormInput,
        BFormGroup,
        BCard,
    },
    directives: {
        'b-modal': VBModal,
        Ripple,
    },
    data: () => ({
        showModalAddModule: false,
        modeEdit: false,
        loading: false,
        moduleData: {
            name: '',
            description: '',
        },
    }),
    methods: {
        addModule() {
            this.showModalAddModule = true
            this.modeEdit = false
        },
        editModule({_id, name, description}) {
            console.log(_id, name, description)
            this.showModalAddModule = true
            this.modeEdit = true
            this.moduleData = {
                _id,
                name,
                description,
            }
        },
        clearInputs() {
            this.moduleData = {
                name: '',
                description: '',
            }
        },
        firstCapital(str) {
            // convertir la primera letra de cada palabra en mayuscula
            return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
        },
        async addUpdateModule() {
            try {
                this.loading = true
                this.moduleData.name = this.firstCapital(this.moduleData.name)
                this.moduleData.description = this.firstCapital(this.moduleData.description)
                console.log(this.moduleData)
                if(this.modeEdit){
                    await this.$store.dispatch('moduleRoles/updateModule', this.moduleData)
                }else{
                    await this.$store.dispatch('moduleRoles/addModule', this.moduleData)
                }
                this.$emit('updateModule', this.moduleData)
                this.clearInputs()
                
            } catch (error) {
                console.log(error)
            }finally{
                this.loading = false
                this.showModalAddModule = false
            }
        }
    },

}
</script>

<style>
div.content-header {
    display: none;
}
</style>