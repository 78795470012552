<template>
  <b-modal
    v-model="showModalAddPermissionModule"
    size="sm"
    cancel-variant="outline-secondary"
    centered
    :title="`${modeEdit ? $t('Edit') : $t('Add')} ${$t('rols.permissionModule')}`"
    hide-footer
  >
    <b-form-group>
      <label for="Name">{{ $t("labels.permissions") }}</label>
      <b-form-input
        id="Name"
        type="text"
        v-model="permissionModuleData.name"
        placeholder="Name permission"
      />
    </b-form-group>

    <b-form-group>
      <label for="Name">{{ "shortName" }}</label>
      <b-form-input
        id="Name"
        type="text"
        v-model="permissionModuleData.shortName"
        placeholder="shortName"
      />
    </b-form-group>

    <b-form-group>
      <label for="Description">{{ $t('labels.description') }}</label>
      <b-form-input
        id="Description"
        type="text"
        v-model="permissionModuleData.description"
        :placeholder="$t('labels.description')"
      />
    </b-form-group>
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="outline-secondary"
        @click="showModalAddPermissionModule = false"
        class="mr-1"
      >
        {{ $t("buttons.cancel") }}
      </b-button>
      <b-button variant="primary" @click="addUpdatePermissionModule()">
        {{ $t("buttons.accept") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data: () => ({
    showModalAddPermissionModule: false,
    modeEdit: false,
    loading: false,
    permissionModuleData: {
      name: "",
      shortName: "",
      description: "",
    },
    moduleSelected: {},
  }),
  methods: {
    addPermission(moduleSelected) {
      this.moduleSelected = moduleSelected;
      this.showModalAddPermissionModule = true;
      this.modeEdit = false;
    },
    editModule({ _id, name, description }) {
      console.log(_id, name, description);
      this.showModalAddPermissionModule = true;
      this.modeEdit = true;
      this.permissionModuleData = {
        _id,
        name,
        description,
      };
    },
    clearInputs() {
      this.permissionModuleData = {
        name: "",
        description: "",
      };
    },
    firstCapital(str) {
        // convertir la primera letra de cada palabra en mayuscula
        return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
    },
    toLowerCase(str) {
        // // convertir la primera letra de cada palabra en minuscula remplazar los espacios por pisos bajo 
        return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toLowerCase() + txt.substr(1).toLowerCase();}).replace(/ /g, '_');
    },
    async addUpdatePermissionModule() {
      try {
        this.loading = true;
        const newPermission = {
          // primeras letras dela oreacion en mayuscula
          name: this.firstCapital(this.permissionModuleData.name),
          shortName: this.toLowerCase(this.permissionModuleData.shortName),
          description: this.toLowerCase(this.permissionModuleData.description),
        };
        this.moduleSelected.permissions.push(newPermission);
        await this.$store.dispatch(
          "moduleRoles/updateModule",
          this.moduleSelected
        );
        this.$emit("updatePermissionModule", this.moduleSelected);
        this.clearInputs();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.showModalAddPermissionModule = false;
      }
    },
  },
};
</script>

<style>
</style>